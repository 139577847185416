import React, {useState, useEffect} from 'react'

import styled from "styled-components";
import { Slide } from 'react-reveal';

//components
import TopMenu from "../InnerComponents/TopMenu.js";
import CashFlowTable from './CashFlowTable.js';
import PopupComponent from './PopupComponent.js';

//icon
import { FaPlus } from "react-icons/fa";
import { MdKeyboardArrowUp } from "react-icons/md";

import axios from "axios";
import RegistroCashflow from './RegistroCashflow.js';

function CashFlow({username, password}) {


  const mesi = ['Gennaio', 'Febbraio', 'Marzo', 'Aprile', 'Maggio', 'Giugno', 'Luglio', 'Agosto', 'Settembre', 'Ottobre', 'Novembre', 'Dicembre'];
  const mesiModificati = ['Mese non valido', 'Gennaio', 'Febbraio', 'Marzo', 'Aprile', 'Maggio', 'Giugno', 'Luglio', 'Agosto', 'Settembre', 'Ottobre', 'Novembre', 'Dicembre'];

  const [aggiornamento, setAggiornamento] = useState("Aggiornato \u2713");
  //btn aggiungi
  const [aggiungi, setAggiungi] = useState(false);

  function handleSeeAggiungi(){
    setAggiungi(!aggiungi);
  }

  //visualizzazione popup
  const [show, setShow] = useState(false);
  const [type, setType] = useState(null);

  function handleShow(e){
    setType(e.target.id);
    setShow(!show);
  }

  function closePopup(){
    setShow(false);
  }

  //categorie
  const [categorie, setCategorie] = useState(null);
  const [macro, setMacro] = useState(null);
  const [datiPagamenti, setDatiPagamenti] = useState(null);
  
  const fetchData = async () => {
    const response = await fetch('https://www.app.nauticsm.it/categorie-cashflow');
    
    const dati = await response.json();
    setCategorie(dati.categorie);
    setMacro(dati.macro);
    setDatiPagamenti(dati.pagamenti);
    
  };

  

  const [ricarica, setRicarica] = useState(false);

  

  function reset(){
    setRicarica(false);
  }

  //dati tabella
  const [dati, setDati] = useState(null);
  const [importi, setImporti] = useState(null);
  const [nascosti, setNascosti] = useState(null);

  const fetchDataTable = async () => {
    try {
    const response = await fetch('https://www.app.nauticsm.it/cash-flow');
    console.log("chiamata table")
    const datiJson = await response.json();
    setDati(datiJson);
    console.log(datiJson);
    setImporti(datiJson.importi);
    setNascosti(datiJson.nascosti);
    
    } catch (error) {
    console.error('Errore nel recupero dei dati:', error);
    }
  };

  //fatture
  const [fatture, setFatture] = useState(null);
  const [fattureAcquisto, setFattureAcquisto] = useState(null);
  const [fattureAcquistoRiba, setFattureAcquistoRiba] = useState(null);
  const [fattureRiba, setFattureRiba] = useState(null);
    
  const fetchDataFatture = async () => {
    try {
      const response = await axios.get('https://www.app.nauticsm.it/fatture-cashflow');
      console.log("chiamata fatture");

      
      setFatture(response.data.risultatoFinale);
      setFattureAcquisto(response.data.risultatoFinaleAcquisto);
      setFattureAcquistoRiba(response.data.risultatoFinaleAcquistoRiba);
      setFattureRiba(response.data.risultatoFinaleRiba);

      setAggiornamento("Aggiornato \u2713");
      
    } catch (error) {
      console.error('Errore durante la richiesta dei dati:', error);
    }
  };

  //ricarica Fatture
  const [tipoRicarica, setTipoRicarica] = useState(null);
  const ricaricaFatture = async () => {
    
    const tipoRicaricaData = {
      tipoRicarica: tipoRicarica
    }

    try {
      const response = await axios.post('https://www.app.nauticsm.it/ricarica-cashflow', tipoRicaricaData);
      
      if(tipoRicarica == 'entrate'){
        setFatture(response.data.risultatoFinale);

        setFattureRiba(response.data.risultatoFinaleRiba);
      
      } else {
        setFattureAcquisto(response.data.risultatoFinale);
        setFattureAcquistoRiba(response.data.risultatoFinaleRiba);
      }

      setAggiornamento("Aggiornato \u2713");
      
    } catch (error) {
      console.error('Errore durante la richiesta dei dati:', error);
    }
  };

  //registro fetch
  const [registroDati, setRegistroDati] = useState(null);
  const fetchRegistro = async () => {

    try {
      const response = await axios.get('https://www.app.nauticsm.it/registro-cashflow');

      setRegistroDati(response.data);
      
    } catch (error) {
      console.error('Errore durante la richiesta dei dati:', error);
    }
  };


  useEffect(() => {
    fetchData();
    fetchDataTable();
    fetchDataFatture();
    fetchRegistro();
  }, []);

  
  function ricaricaDati(){
    setAggiornamento("In aggiornamento...");
    setRicarica(true);
    fetchData();
    fetchDataTable();
    if(tipoRicarica !== null && tipoRicarica !== undefined){
      ricaricaFatture();
    } else{
      fetchDataFatture();
    }
    fetchRegistro();
  }

  //registro importi
  const [bottom, setBottom] = useState(false);
  function handleBottom(){
    setBottom(!bottom);
  }


  //ricarica leggera
  function ricaricaLeggera(){
    fetchData();
    fetchDataTable();
    fetchRegistro();
  }


  return (
    <div>
      
      <div className={bottom ? 'opacity' : ''}>
      <TopMenu name="Cash Flow" username={username}/>
      
      {(dati && importi && fatture && fattureAcquisto) ? (<CashFlowTable mesiPass={mesi} mesiNuoviPass={mesiModificati} dati={dati} importi={importi} fatture={fatture} fattureAcquisto={fattureAcquisto} fattureAcquistoRiba={fattureAcquistoRiba} ricarica={ricaricaDati} aggiornamento={aggiornamento} tipoRicarica={tipoRicarica} setTipoRicarica={setTipoRicarica} fattureRiba={fattureRiba} nascosti={nascosti} ricaricaLeggera={ricaricaLeggera} visualizzazioneTipo={dati.visualizzazione}/> ) : (<p>Caricamento...</p>)}


      <ContainerAggiungi>
        <div>
          <Slide right effect="slideInRight" duration={700} when={aggiungi} >
          <button onClick={handleShow} id="categoria">Categoria</button>
          </Slide>
          <Slide right effect="slideInRight" delay={200} duration={700} when={aggiungi} >
          <button onClick={handleShow} id="importo">Importo</button>
          </Slide>
          <Slide right effect="slideInRight" delay={400} duration={700} when={aggiungi} >
          <button onClick={handleShow} id="metodi">Metodi Pagamento</button>
          </Slide>
        </div>
        <div className='aggiungi' onClick={handleSeeAggiungi}>
          <PlusIcon aggiungi={aggiungi}/>
        </div>
      </ContainerAggiungi>

      {show && (<PopupComponent mesi={mesi} categorie={categorie} macro={macro} close={closePopup} tipo={type} ricarica={ricaricaDati} pagamenti={datiPagamenti}/>)}

      </div>

      <BottomMenuContainer bottom={bottom}>
        <MenuButton bottom={bottom} onClick={handleBottom}/>
        <RegistroCashflow dati={registroDati} ricarica={ricaricaLeggera}/>
      </BottomMenuContainer>
    </div>
  )
}

export default CashFlow;

const MenuButton = styled(MdKeyboardArrowUp )`
  font-size: 50px;
  cursor:pointer;
  background-color: #36454F;
  backdrop-filter: blur(4px); 
  border-radius: 10px;
  z-index: 2000;
  position: fixed;
  right: 50%;
  top: 0;
  color: white;
  transform: ${({bottom}) => (bottom ? 'translate(-50%, -50%) rotate(180deg)' : 'translate(-50%, -70%) rotate(0deg)')}
  
  
`

const BottomMenuContainer = styled.div`
  height: ${({ bottom }) => (bottom ? '80%' : '0%')};;
  width: 95%;
  overflow: hidden;
  transition: height 0.3s ease;
  position: fixed;
  bottom: 0;
  overflow-y: auto;
  left: 50%;
  overflow: visible;
  background-color: #36454F;
  

  transform: translateX(-50%);
  border-radius: 10px 10px 0px 0px;
  z-index: 9000;

  .menu-item ul li{
    display: flex;
    justify-content: space-between;
    align-items: center;
    font-size: 18px;
  }

`

const ContainerAggiungi = styled.div`
  position: fixed;
  bottom: 20px;
  right: 20px;
  display: flex;
  justify-content: space-around;
  align-items: flex-end;
  flex-direction: column;
  

  div{
    display: flex;
    flex-direction: column;
    overflow: hidden;
  }
  button{
    font-size: 20px;
    cursor: pointer;
    border: none;
    outline: none;
    padding: 5px 10px;
    border-radius: 10px;
  }
  button:nth-child(1){
    background-color: red;
    color: white;
  }
  button:nth-child(2){
    margin: 20px 0;
    background-color: green;
    color: white;
  }

  button:nth-child(3){
    margin-bottom: 20px;
    background-color: #213940;
    color: white;
  }
`

const PlusIcon = styled(FaPlus)`
  font-size: 54px;
  color: white;
  padding: 10px;
  transition: all .2s linear;
  ${({ aggiungi }) => (aggiungi && 'transform: rotateZ(45deg);')};
`
