import React, { useEffect, useState } from 'react'
import TopMenu from '../InnerComponents/TopMenu'
import axios from "axios";
import styled from "styled-components"

//icon
import { PiNumberOneBold } from "react-icons/pi";
import { PiNumberThreeBold } from "react-icons/pi";

function Iva() {

  const mesi = ['Gennaio', 'Febbraio', 'Marzo', 'Aprile', 'Maggio', 'Giugno', 'Luglio', 'Agosto', 'Settembre', 'Ottobre', 'Novembre', 'Dicembre'];

  const [datiTabella, setDatiTabella] = useState(null);
  const [visualizzazione, setVisualizzazione] = useState(null);
  const [visualizzazioneTotale, setVisualizzazioneTotale] = useState(null);
  const [start, setStart] = useState(false);

  async function datiIva() {
    try {
      const response = await axios.get('https://www.app.nauticsm.it/dati-iva');
      setDatiTabella(response.data);
      setVisualizzazione(response.data.tipo);    
      setVisualizzazioneTotale(response.data.visualizzazione);
      setStart(true);
     

    } catch (error) {
      console.error('Errore durante la richiesta POST:', error);
    }

  }

  useEffect(() => {
    datiIva();
  }, [])


  //cambia visualizzazione
  async function cambiaVisualizzazione() {
    try {
      const data = {
        tipo: visualizzazione
      }

      const response = await axios.post('https://www.app.nauticsm.it/cambia-visualizzazione', data);
      
      setVisualizzazioneTotale(response.data);

    } catch (error) {
      console.error('Errore durante la richiesta POST:', error);
    }

  }

  function handleChangeView(){
    
    if(visualizzazione === 'mensile'){
      setVisualizzazione('trimestrale')
    }else {
      setVisualizzazione('mensile');
    }
    
  }

  useEffect(() => {
    if(start){
      cambiaVisualizzazione();
    }
  }, [visualizzazione])

  return (
    <div>
        <TopMenu name="IVA" />
        <UpperMenu visualizzazione={visualizzazione}>
          {datiTabella && (
            <div className='icon-container tooltip' onClick={handleChangeView}>
              <span class="tooltiptext">Visualizzazione IVA</span>
              {visualizzazione === 'mensile' ? (
                <ThreeIcon onClick={handleChangeView}/>
              ) : (
                <OneIcon onClick={handleChangeView}/>
              )}
            </div>
          )}
          
        </UpperMenu>
        <Container>
          {datiTabella ? (
            <div className='scroll'>
                <table className='scroll-table'>
                  <thead>
                    <tr className='intestazione'>
                      <th className='block first-column'>Categoria</th>
                      {mesi.map((item) => (
                        <th className='columnNew'>{item}</th>
                      ))}
                    </tr>
                  </thead>
                  <tbody>
                    
                    <tr>
                      <td className='block first-column'>IVA Fatture Vendita</td>
                      {datiTabella.vendita.map((item) => (
                        <td className='column'>{Number(item).toLocaleString('it-IT', {
                          style: 'currency',
                          currency: 'EUR'
                        })}</td>
                      ))}

                    </tr>

                    <tr>
                      <td className='block first-column'>IVA Fatture Acquisto</td>
                      {datiTabella.acquisto.map((item) => (
                        <td className='column'>{Number(item).toLocaleString('it-IT', {
                          style: 'currency',
                          currency: 'EUR'
                        })}</td>
                      ))}

                    </tr>

                    <tr>
                      <td className='block first-column last'>Totale</td>
                      {datiTabella.totale.map((item) => (
                        <td className='column last'>{Number(item).toLocaleString('it-IT', {
                          style: 'currency',
                          currency: 'EUR'
                        })}</td>
                      ))}

                    </tr>

                    <tr>
                      <td className='block first-column last'>Visualizzazione CashFlow</td>
                      {visualizzazioneTotale.map((item) => (
                        <td className='column last'>{Number(item).toLocaleString('it-IT', {
                          style: 'currency',
                          currency: 'EUR'
                        })}</td>
                      ))}

                    </tr>
                    
                  </tbody>
                </table>
              </div>
            ) : (<p>Caricamento...</p>)}
        </Container>
    </div>
  )
}

export default Iva;

const UpperMenu = styled.div`
  height: 5vh;
  width: 100%;
  display: flex;
  justify-content: flex-start;
  align-items: center;
  padding-left: 2%;
  margin-top: 30px;

  

  .icon-container{
    border: 1px solid rgba(0,0,0,.5);
    border-bottom: ${props => (props.seeFilter ? 'none' : '1px solid rgba(0,0,0,.5)')};
    width: fit-content;
    padding: 5px 10px;
    display: flex;
    align-items: center;
    border-radius: ${props => (props.seeFilter ? '5px 5px 0px 0px' : '5px')};
    cursor: pointer;
    color: rgba(0,0,0,.5);
    transition: all .3s linear;
  }

  .icon-container:hover{
    border: 1px solid black;
    color: black;
  }

  

`

const Container = styled.div`
  height: 85vh;
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
`

const OneIcon = styled(PiNumberOneBold)`
  font-size: 20px;
  cursor: pointer;
`

const ThreeIcon = styled(PiNumberThreeBold)`
  font-size: 20px;
  cursor: pointer;
`