//style
import './styles/Login.css';

//images
import logo from "../images/logo.png";

//icon
import { FaEye } from "react-icons/fa";
import { FaEyeSlash } from "react-icons/fa";
import { FaSpinner } from 'react-icons/fa';
import { BounceLoader } from 'react-spinners';


//components
import CopyrightComp from "./CopyrightComp.js";

//other
import styled from 'styled-components';
import React, {useEffect, useState} from "react";
import { useNavigate } from 'react-router-dom';

function App( {onSelect} ) {

  const [errorUsername, setErrorUsername] = useState(false);
  const [errorPassword, setErrorPassword] = useState(false);
  const [errorTentativi, setErrorTentativi] = useState(null);
  //data to send
  const [dataSend, setDataSend] = useState({
    username: "",
    password: "",
    rememberMe: false
  });

  useEffect(() => {
    console.log("entgrat");
    const savedUsername = localStorage.getItem('username');
    const savedPassword = localStorage.getItem('password');

    if(savedUsername && savedPassword){
      setDataSend({
        username: savedUsername,
        password: savedPassword,
        rememberMe: true
      })
    }

  }, [])


  //route
  const navigate = useNavigate();

  function handleRedirect(){
    onSelect(true);
    navigate('/home', { state: { username: dataSend.username, password: dataSend.password } });
  }

  //visible password
  const [seePassword, setSeePassword] = useState(false);
  function handleSeePassword(){
    setSeePassword((prevSeePassword) => !prevSeePassword);
  }

  

  function handleChange(event) {
    const newDataSend = { ...dataSend };

    if (event.target.name === "username") {
      newDataSend.username = event.target.value;
    } else if (event.target.name === "password") {
      newDataSend.password = event.target.value;
    } else if (event.target.name === "rememberMe") {
      newDataSend.rememberMe = event.target.checked;
    }

    setDataSend(newDataSend);
  }

  const [isLoading, setIsLoading] = useState(false);
  async function handleSubmit(e){
    e.preventDefault();

    setIsLoading(true);   

    console.log(dataSend.rememberMe);
    if (dataSend.rememberMe) {
      localStorage.setItem('username', dataSend.username);
      localStorage.setItem('password', dataSend.password);
    } else {
      localStorage.removeItem('username');
      localStorage.removeItem('password');
    }
    
    
    try {
      // Ritarda l'esecuzione di 1 secondo
      
        const response = await fetch('https://www.app.nauticsm.it/login', {
          method: 'POST',
          headers: {
            'Content-Type': 'application/json',
          },
          body: JSON.stringify(dataSend),
        });
    
        const responseData = await response.json();

        console.log(responseData);
    
        if (responseData.alert === 1) {
          
          handleRedirect();
        } else {
          if (responseData.tipo === 0) {
            setIsLoading(false);
            setErrorUsername(true);
            setErrorPassword(false);
            setErrorTentativi(null);
          } else if(responseData.tipo === 1){
            setIsLoading(false);
            setErrorPassword(true);
            setErrorUsername(false);
            setErrorTentativi(null);
          } else {
            setIsLoading(false);
            setErrorTentativi(responseData.message);
            setErrorPassword(false);
            setErrorUsername(false);
          }
        }

    } catch (error) {
      console.error(error.message);
    }
  
  }

  //aggiungi
  const [aggiungi, setAggiungi] = useState(false);
  const [masterPassw, setMasterPassw] = useState(false);
  function handleAggiungi(){
    setAggiungi(!aggiungi);

    
  }

  function handleSeeMasterPassword(){
    setMasterPassw(!masterPassw);
  }

  //nuovo utente
  const [datiNuovo, setDatiNuovo] = useState({
    nuovoUtente: '',
    nuovaPassword: '',
    passwordAmministratore: ''
  })

  function handleNuoviDati(e) {
    const tipo = e.target.id;
    const value = e.target.value;

    setDatiNuovo(prevDatiNuovo => ({
      ...prevDatiNuovo,
      [tipo]: value,
    }));
  }

  const [displayNuovi, setDisplayNuovi] = useState(false);
  const [errorNuovi, setErrorNuovi] = useState(false);

  async function handleSubmitNuovi(event){ 
    event.preventDefault();
    try {
      // Ritarda l'esecuzione di 1 secondo
      
      const response = await fetch('https://www.app.nauticsm.it/nuovo-utente', {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
        },
        body: JSON.stringify(datiNuovo),
      });
  
      const responseData = await response.json();
  
      if (responseData.check) {
        setDisplayNuovi(true);
        setAggiungi(true);
      } else {
        setErrorNuovi(true);
                
      }

      

    } catch (error) {
      console.error(error.message);
    }
  }

  const [strength, setStrength] = useState(0);

    // Funzione per calcolare la sicurezza della password
  function calculateStrength(password){
      let score = 0;

      if (!password) {
          return 0;
      }
      score += password.length * 10;

      return score;
  };
  React.useEffect(() => {

      setStrength(calculateStrength(datiNuovo.nuovaPassword));

  }, [datiNuovo.nuovaPassword]);

  // Determina lo stile della barra di progresso in base alla forza della password
  const barColor = strength >= 100 ? 'green' : 'red';
  const wrapperStyle = {
    marginTop: "10px",
    width: '100%',
    height: '17px', 
    backgroundColor: 'lightgray', 
    borderRadius: '5px', 
    overflow: 'hidden',
  };

  // Stile per la barra di progresso interna (colore variabile)
  const progressStyle = {
      width: `${Math.min(strength, 100)}%`,
      backgroundColor: barColor, 
      height: '100%', 
  };
  
  // Determina lo stile del testo di stato in base alla forza della password
  const statusStyle = {
      color: strength >= 100 ? 'green' : 'red',
  };

  // Determina il testo di stato in base alla forza della password
  const statusText = strength >= 100 ? 'Password sicura :)' : 'Password non sicura!';

    
  return (
    //main login
    <main className='main-login' data-testid="cypress-home-content">
      {/* logo */}
      <div className='logo'>
        <img src={logo} alt="Logo azienda" />
      </div>
      {/* login form */}
      <div  className='login-form'>

        
          <form>
          
          {aggiungi ? (
            <React.Fragment>
               <label>Nuovo Username</label>
            <br/>
            <input type="text" name="username" id="nuovoUtente" onChange={handleNuoviDati} required placeholder='username' /><br/>

            <br />

            <label>Nuova Password</label>
            <br/>
            <div className="password-input-container">
              <input type="text" name="password" id="nuovaPassword" onChange={handleNuoviDati} required placeholder='Password'/>
              <br/>
              <div style={wrapperStyle}>
                <div style={progressStyle}></div>
              </div>
              <p style={statusStyle}>{statusText}</p>
            </div>

            <br />
            
            <label>Password Amministratore</label>
            <br/>
            <div className="password-input-container">
              {masterPassw ? (
                <input type="text" name="password" id="passwordAmministratore" onChange={handleNuoviDati} required placeholder='Password'/>
              ) : (
                <input type="password" id="passwordAmministratore" onChange={handleNuoviDati} required name="password" placeholder='Password'/>
              )}

              <div className="eye-icon" onClick={handleSeeMasterPassword}>
                {masterPassw ? (
                  <EyeIconOpen />
                ) : (
                  <EyeIcon />
                )}
              </div>
            </div>
            <br/><br/>

            <input type="submit" onClick={handleSubmitNuovi} name="submit" value="Login"/>
            {displayNuovi && (
              <div className='nuovo-utente'>
                <p>Utente creato con successo!</p>
              </div>
            )}

            {errorNuovi && (
              <div className='error'>
                <p>Utente non autorizzato!</p>
              </div>
            )}
            <Aggiunta>
              <p onClick={handleAggiungi}>Login Utente</p>
            </Aggiunta>
            </React.Fragment>

          ) : (
            <React.Fragment>
            <label>Username</label>
            <br/>
            <input type="text" onChange={handleChange} value={dataSend.username} name="username" required placeholder='Username' /><br/>
            

            <br/>

            <label>Password</label>
            <br/>
            <div className="password-input-container">
              {seePassword ? (
                <input type="text" name="password" onChange={handleChange} value={dataSend.password} required placeholder='Password'/>
              ) : (
                <input type="password" required onChange={handleChange} value={dataSend.password} name="password" placeholder='Password'/>
              )}

              <div className="eye-icon" onClick={handleSeePassword}>
                {seePassword ? (
                  <EyeIconOpen />
                ) : (
                  <EyeIcon />
                )}
              </div>
            </div>


            {(errorPassword) && (<p className='error'>Username o Password non validi</p>)}
            {(errorUsername) && (<p className='error'>Errore generale riprovare piu tardi...</p>)}
            {(errorTentativi) && (<p className='error'>{errorTentativi}</p>)}

            <br/><br/>

            
            <label style={{cursor: 'pointer'}}><input type="checkbox" name="rememberMe" checked={dataSend.rememberMe} onChange={handleChange} style={{width: 'fit-content', cursor: 'pointer'}}/> Ricordami</label><br/>
            <input type="submit" style={{opacity: errorTentativi !== null ? '.8' : '1' }} onClick={handleSubmit} name="submit" value="Login" />
            
            
            
            
            <Aggiunta>
              <p onClick={handleAggiungi}>Aggiungi Utente</p>
            </Aggiunta>
            </React.Fragment>
          )}

          </form>
        
        
       
        

      </div>
      

      <CopyrightComp />
      {isLoading && (
        <Loader>
          <BounceLoader size={200} color={'#213940'} loading={isLoading} />
        </Loader>
      )}
    </main>
  );
}

export default App;


const EyeIcon = styled(FaEyeSlash)`
  font-size: 22px;
  margin-left: 10px;
  cursor: pointer;
  padding-top: 5px;

`


const EyeIconOpen = styled(FaEye)`
  font-size: 22px;
  margin-left: 10px;
  cursor: pointer;
  padding-top: 5px;

`

const Aggiunta = styled.div`
  margin-top: 10px;
  p{
    color: #1eafd6;
    text-decoration: underline;
    text-align: center;
    cursor: pointer;
    font-size: 18px;
  }
`

const Loader = styled.div`
  position: fixed;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  background-color: rgba(255,255,255,.7);
  z-index: 9999;
  height: 100%;
  width: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
`