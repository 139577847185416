import React, {useState, useEffect} from 'react'

import styled from "styled-components";
import Datetime from 'react-datetime';
import 'react-datetime/css/react-datetime.css';
import moment from "moment"

//icon
import { TiTick } from "react-icons/ti";
import { IoMdClose } from "react-icons/io";

//componente
import Correct from '../InnerComponents/Correct';
import CategoriaCash from './CategoriaCash';


function PopupComponent({mesi, categorie, macro, close, tipo, ricarica, pagamenti}) {
  
  //correct
  const [tick, setTick] = useState(false);

  //chiudi popup
  function handlePopup(){
    close();
  }

  

  //importo
  const [importo, setImporto] = useState({
    category: 'seleziona',
    importo: 0,
    mese: 'seleziona',
    tipo: 'singolo'

  })
  function handleImporto(e){
    const name = e.target.name;
    const value = e.target.value;

    setImporto(prevImporto => ({
      ...prevImporto,
      [name]: value,
    }));
  }

  function handleDateSingle(date){
    const formattedDate = moment(date).format('MM/YYYY');
    setImporto(prevImporto => ({
      ...prevImporto,
      mese: formattedDate,
    }));
  }

  function handleImportoClick(){
    setTick(!tick);

    setTimeout(() => {
      close();
      
    },1000);

    

    fetch('https://www.app.nauticsm.it/importo-cashflow', { 
      method: 'POST',
      headers: {
        'Content-Type': 'application/json',
      },
      body: JSON.stringify(importo),
    })
    .then(response => response.json())
    .then(data => {
        ricarica();
    })
    .catch(error => {
        console.error('Errore durante l\'invio dei dati al server:', error);
    });
  }

  //mese
  const [startMonth, setStartMonth] = useState(null);
  const [months, setMonths] = useState(2);
  const [years, setYears] = useState(1);
  

  useEffect(() => {
    calculateMesiIntervallo();
  }, [startMonth, months, years]);

  const handleStartMonth = (event) => {
    setStartMonth(event.target.value);
  }

  const handleIntervallo = (event) => {
    setMonths(Number(event.target.value));
  };

  function handleYears(e){
    setYears(Number(e.target.value));
  }

  const [sendIntervallo, setSendIntervallo] = useState({
    categoria: 'seleziona',
    importo: 0,
    intervallo: 0,
    years: 1
  })

  const calculateMesiIntervallo = () => {
    if (startMonth !== null) {
      let mese = startMonth;
      
      
      const categoria = importo.category;
      const nuovoImporto = importo.importo;
      const intervalloMesi = months;
      console.log(years);
      const yearsDurata = years;

      setSendIntervallo(prevState => ({
        ...prevState,
        categoria: categoria,
        importo: nuovoImporto,
        startMonth: mese,
        intervallo: intervalloMesi,
        years: yearsDurata
      }));
    }

    
  };


  function handleClickIntervallo(){
    setTick(!tick);
    console.log(sendIntervallo);

    setTimeout(() => {
      close();
    },1000);

    fetch('https://www.app.nauticsm.it/intervallo-cashflow', { 
      method: 'POST',
      headers: {
        'Content-Type': 'application/json',
      },
      body: JSON.stringify(sendIntervallo),
    })
    .then(response => response.json())
    .then(data => {
        ricarica();
    })
    .catch(error => {
        console.error('Errore durante l\'invio dei dati al server:', error);
    });
  }
  
  

  const [switchBtn, setSwitchBtn] = useState('occasionale');

  function handleSwitch(tipo){
    setTimeout(() => {
      setSwitchBtn(prevValue => {
        if(prevValue === 'occasionale'){
          return 'abituale'
        } else {
          return 'occasionale'
        }
      })
    }, 200);
  }

  const [delayEntrate, setDelayEntrate] = useState({
    Bonifici: null,
    Contanti: null,
    Contrassegno: null,
    PaypalCredito: null,
    Rid: null,
    Riba: null
  })
  const [delayUscite, setDelayUscite] = useState({
    Bonifici: null,
    Contanti: null,
    Contrassegno: null,
    PaypalCredito: null,
    Rid: null,
    Riba: null
  })

  function handleEntrate(e){
    const {name, value} = e.target;

    const valore = parseInt(value, 10);
    

    setDelayEntrate(prevEntrate => ({
      ...prevEntrate,
      [name]: valore
    }))
  }


  

  function handleUscite(e){
    const {name, value} = e.target;

    const valore = parseInt(value, 10);

    setDelayUscite(prevEntrate => ({
      ...prevEntrate,
      [name]: valore
    }))

    
  }

  function handleClickMetodi(){
    setTick(!tick);
    
    setTimeout(() => {
      close();
    },1000);
    
    const metodi = {
      delayEntrate: delayEntrate,
      delayUscite: delayUscite
    }

    console.log(metodi);
    

    fetch('https://www.app.nauticsm.it/metodi-cashflow', { 
      method: 'POST',
      headers: {
        'Content-Type': 'application/json',
      },
      body: JSON.stringify(metodi),
    })
    .then(response => response.json())
    .then(data => {
    
      ricarica();
    })
    .catch(error => {
        console.error('Errore durante l\'invio dei dati al server:', error);
    });
  }

  //first switch
  const [switchBtnFirst, setSwitchBtnFirst] = useState('macro');

  function handleSwitchFirst(tipo){
    setTimeout(() => {
      setSwitchBtnFirst(prevValue => {
        if(prevValue === 'macro'){
          return 'nuova'
        } else {
          return 'macro'
        }
      })
    }, 200);
  }


  //importo macrocategorie
  const [importoMacro, setImportoMacro] = useState({
    category: 'seleziona',
    importo: 0,
    mese: 'seleziona', 
    tipo: 'macro',
    nome: ''
  })
  function handleImportoMacro(e){
    const name = e.target.name;
    const value = e.target.value;

    setImportoMacro(prevImporto => ({
      ...prevImporto,
      [name]: value,
    }));

    
  }

  function handleDate(date) {
    const formattedDate = moment(date).format('MM/YYYY');
    setImportoMacro(prevImporto => ({
      ...prevImporto,
      mese: formattedDate,
    }));
  }

 

  function handleImportoClickMacro(){
    setTick(!tick);

    setTimeout(() => {
           
      close();
      
    },1000);

    fetch('https://www.app.nauticsm.it/importo-cashflow', { 
      method: 'POST',
      headers: {
        'Content-Type': 'application/json',
      },
      body: JSON.stringify(importoMacro),
    })
    .then(response => response.json())
    .then(data => {
      
      ricarica();
    })
    .catch(error => {
        console.error('Errore durante l\'invio dei dati al server:', error);
    });
  }

  //ricarica
  function handleRicarica(){
    ricarica();
  }

  return (
    <Popup>
        <InnerPopup>
          <CloseIcon onClick={handlePopup}/>

          {tick ? (<TickContainer>
            <InnerContainer><Tick /></InnerContainer>            
        </TickContainer>) : (
            
            <React.Fragment>
              {tipo === 'categoria' && (
              <CategoriaCash close={handlePopup} categorie={categorie} ricarica={handleRicarica}/>
            )}

          
            {tipo === 'importo' && (
              <React.Fragment>
                  <Switch1 switchBtnFirst={switchBtnFirst}>
                      <p>Macrocategoria</p>
                      <div onClick={handleSwitchFirst} className='switch'>
                        <div className='inner-switch'></div>
                      </div>
                      <p>Nuova Categoria</p>
                  </Switch1>

                  {switchBtnFirst === 'macro' ? (
                    <>
                    <div className='popup-title'>
                      <h2>Aggiungi valore ad una macrocategoria</h2>
                    
                    </div>
                    <div className='popup-form'>
                      <label for="category">Seleziona una Macrocategoria:</label>
            
                      <select name="category" id="cat"  onChange={handleImportoMacro}>
                        <option disabled selected value="seleziona">Seleziona una categoria</option>
                        {macro
                          .sort((a, b) => a.localeCompare(b)) // Ordina gli elementi in ordine alfabetico
                          .map((item) => (
                              <option value={item} key={item}>{item}</option>
                          ))
                        }
                        
                      </select>

                      <br /><br />

                      <label for="importo">Inserisci Nome:</label>
                      <input onChange={handleImportoMacro} name="nome" type="text" placeholder="Stipendio"/>
            
                      <br /><br />

                      <div style={{display: 'flex', alignItems: 'center', justifyContent: 'flex-start'}}>
                        <label for="importo">Inserisci importo <span className='advise'>(usare . per i decimali es: 100.3)</span>:</label>
                        <input onChange={handleImportoMacro} name="importo" type="number" placeholder="1000"/>
                      </div>
                      
                      <br /><br />

                      <div style={{display: 'flex', alignItems: 'center'}}>
                        <label for="mese">Seleziona mese:</label>
                        <div>
                          <Datetime
                            value={importoMacro.mese === 'seleziona' ? '' : moment(importoMacro.mese, 'MM/YYYY')}
                            onChange={handleDate}
                            dateFormat="MM/YYYY"
                            timeFormat={false}
                            inputProps={{placeholder: 'Seleziona'}}
                            viewMode="years"
                          />
                        </div>
                      </div>

                      <br /><br />
                      
                    </div>
                    <div className='send-button'>
                        <button id="aggiungi" onClick={handleImportoClickMacro}>Aggiungi</button>
                      </div>
                    </>
                  ) : (
                    <>
                    <div className='popup-title'>
                      <h2>Aggiungi valore</h2>
                    
                    </div>
                    <div className='popup-form'>
                      <label for="category">Seleziona una Categoria:</label>
            
                      <select name="category" id="cat"  onChange={handleImporto}>
                        <option disabled selected value="seleziona">Seleziona una categoria</option>
                        {categorie
                          .sort((a, b) => a.localeCompare(b)) // Ordina gli elementi in ordine alfabetico
                          .map((item) => (
                              <option value={item} key={item}>{item}</option>
                          ))
                        }
                        
                      </select>
            
                      <br /><br />
                      
                      <div style={{display: 'flex', alignItems: 'center', justifyContent: 'flex-start'}}>
                        <label for="importo">Inserisci importo <span className='advise'>(usare . per i decimali es: 100.3)</span>:</label>
                        <input onChange={handleImporto} name="importo" type="number" placeholder="1000"/>
                      </div>
                      
            
                      <br /><br />
    
                      <Switch switchBtn={switchBtn}>
                          <p>Singolo</p>
                          <div onClick={handleSwitch} className='switch'>
                            <div className='inner-switch'></div>
                          </div>
                          <p>Ricorrente</p>
                      </Switch>
    
                      <br /><br />
    
                      {switchBtn === "abituale" ? (
                        <React.Fragment>
                          <label for="mese">Seleziona mese d'inizio:</label>
                          <select name="mese" id="cat" onChange={handleStartMonth}>
                            <option disabled selected value="seleziona">Seleziona mese d'inizio</option>
                            {mesi.map((item) => (
                              <option value={item}>{item}</option>
                            ))}
                          </select>
                          <br /><br />
                          {startMonth === null ? (<p>Seleziona il mese di partenza</p>):(
                            <>
                              <label className="slider-label">
                                <p>Intervallo:</p>
                                <div className="slider-container">
                                  <input
                                    type="range"
                                    min="1"
                                    max="12"
                                    step="1"
                                    value={months}
                                    onChange={handleIntervallo}
                                    className="slider"
                                  />
                                  {months === 1 ? (
                                    <div className="slider-thumb">ogni mese</div>
                                  ) : (
                                    <div className="slider-thumb">ogni {months} mesi</div>
                                  )}
                                </div>
                              
                              </label>
                              <br /><br />
                              <label for="years">Inserire Durata in anni:</label>
                              <input name="years" type="number" max="5" min="1" onChange={handleYears}/>
                            </>
                          
                          )}
                        </React.Fragment>
                        
                      ) : (
                        <React.Fragment>
                          <div style={{display: 'flex', alignItems: 'center'}}>
                            <label for="mese">Seleziona mese:</label>
                            <Datetime
                              value={importo.mese === 'seleziona' ? '' : moment(importo.mese, 'MM/YYYY')}
                              onChange={handleDateSingle}
                              dateFormat="MM/YYYY"
                              timeFormat={false}
                              inputProps={{placeholder: 'Seleziona'}}
                              viewMode="years"
                            />
                          </div>
                        </React.Fragment>
                      )}
            
                      <br /><br />
            
                    </div>
          
                    <div className='send-button'>
                      {switchBtn === 'abituale' ? (<button id="aggiungi" onClick={handleClickIntervallo}>Aggiungi</button>) : (<button id="aggiungi" onClick={handleImportoClick}>Aggiungi</button>)}
                    </div>
                      </>
                    )}
                  
                </React.Fragment>
              )}

              {tipo === 'metodi' && (
                <React.Fragment>
                  <div className='popup-title'>
                    <h2>Imposta Ritardi Pagamenti</h2>
                
                  </div>
                  <Delay>
                    <div style={{marginRight: '50px'}}>
                      <h3>Entrate</h3>
                      {pagamenti
                        
                        .map((item) => (
                          <>
                            <div className='label'>
                              <label>{item.tipo}:</label>
                              <input
                                  type="number"
                                  placeholder={item.delay}
                                  name={item === 'Paypal/Carta di Credito' ? 'PaypalCredito' : item.tipo}
                                  
                                  onChange={handleEntrate}
                              />
                              
                            </div>
                            <br/>
                          </>
                        ))
                      }
                    </div>
                    <div>
                      <h3>Uscite</h3>
                      {pagamenti
                        
                        .map((item) => (
                          <>
                            <div className='label'>
                              <label>{item.tipo}:</label>
                              <input
                                  type="number"
                                  placeholder={item.delayuscite}
                                  name={item === 'Paypal/Carta di Credito' ? 'PaypalCredito' : item.tipo}
                                  
                                  onChange={handleUscite}
                              />
                              
                            </div>
                            <br/>
                          </>
                        ))
                      }
                    </div>

                  </Delay>
                  <div className='send-button'>
                    <button id="aggiungi" onClick={handleClickMetodi}>Salva</button>
                  </div>
                </React.Fragment>
              )}
            </React.Fragment>
          )}

              
            
        </InnerPopup>
    </Popup>
  )
}

export default PopupComponent;

const Popup = styled.div`
  position: fixed;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  background-color: rgba(0,0,0,.7);
  width: 100%;
  height: 100%;
  z-index: 2000;

`

const InnerPopup = styled.div`
  position: fixed;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  z-index: 3000;
  background-color: white;
  width: fit-content;
  min-height: 30vh;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  border-radius: 10px;
  min-width: 30%;

  padding: 0 50px 0px 25px;

  .popup-title{
    width: 100%;
    display: flex;
    justify-content: space-between;
    margin-top: 2vh;
    align-items: center;
    margin-bottom: 20px;
  }


  .popup-form input{
    border: 1px solid black;
    font-size: 20px;
    padding: 3px 8px;
  }

  .popup-form label{
    margin-right: 20px;
    font-size: 20px;
  }

  .popup-form select{
    font-size: 20px;
    border: 1px solid black;
    cursor: pointer;
  }
  
  .send-button{
    display: flex;
    justify-content: center;
    margin-bottom: 2vh;
    margin-top: 20px;
  }

  .send-button button{
    box-shadow: 0px 0px 5px rgba(0,0,0,.3);
    cursor: pointer;
    font-size: 20px;
    border: none;
    
    outline: none;
    border-radius: 5px;
    padding: 8px 50px;
    
  }
`

const CloseIcon = styled(IoMdClose)`font-size: 36px; cursor:pointer; position: fixed; top: 5px; right: 5px;

    &:hover{
        background-color: #213940;
        border-radius: 5px;
        color: white;
        transition: all .3s linear;
    }
`

const Switch = styled.div`
  display: flex;
  justify-content: flex-start;
  align-items: center;
  

  p{
    font-size: 20px;
  }

  .switch{
    width: 75px;
    border-radius: 12px;
    background-color: lightgrey;
    height: 24px;
    display: flex;
    ${({ switchBtn }) => (switchBtn === 'occasionale' ? 'justify-content: flex-start;' : 'justify-content: flex-end;')};
    
    align-items: center;
    margin: 0px 10px;
    cursor: pointer;
  }

  .inner-switch{
    background-color: #213940;
    height: 22px;
    width: 22px;
    border-radius: 50%;
    margin: 1px;
    cursor: pointer;
  }
`

const Switch1 = styled.div`
  display: flex;
  justify-content: flex-start;
  align-items: center;
  margin-top: 3vh;
  margin-left: 2%;
  p{
    font-size: 20px;
  }

  .switch{
    width: 75px;
    border-radius: 12px;
    background-color: lightgrey;
    height: 24px;
    display: flex;
    ${({ switchBtnFirst }) => (switchBtnFirst === 'macro' ? 'justify-content: flex-start;' : 'justify-content: flex-end;')};
    
    align-items: center;
    margin: 0px 10px;
    cursor: pointer;
  }

  .inner-switch{
    background-color: #213940;
    height: 22px;
    width: 22px;
    border-radius: 50%;
    margin: 1px;
    cursor: pointer;
  }
`

const Delay = styled.div`

display: flex;
justify-content: space-around;
align-items: center;

.popup-title h2{
  margin-left: 5%;
}

input{
  border: 1px solid black;
  font-size: 20px;
  padding: 3px 8px;
  width: 50px;
}
h3{
  margin-bottom: 10px;
}
label{
  margin-right: 20px;
  font-size: 20px;
  
}

select{
  font-size: 20px;
  border: 1px solid black;
  cursor: pointer;
  
}

.label{
  display: flex;
  justify-content: space-between;
  align-items: center;
}
.send-button{
  display: flex;
  justify-content: center;
  margin-bottom: 2vh;
  margin-top: 20px;
}

.send-button button{
  background-color: #213940;
  cursor: pointer;
  font-size: 20px;
  border: none;
  color: #fafafa;
  outline: none;
  padding: 5px 10px;
  &:hover{
    background-color: rgba(33, 57, 64, .8);
    transition: all .3s linear;
  }
}
`
const Tick = styled(TiTick)`
    font-size: 100px;
    color: white;
`

const TickContainer = styled.div`
    
    width: 100%;
    min-height: inherit;
    height: 100%;
    display: flex;
    align-items: center;
    justify-content: center;
`
const InnerContainer = styled.div`
    
    background-color: green;
    border-radius: 50%;
    padding: 30px;
    display: flex;
    align-items: center;
    justify-content: center;
    margin: 20px auto;
    
`